.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.wrapper:not(.wrapperNoStyle) {
    border: 1px solid #ddd;
    background-color: #fafafa;
    border-radius: 5px;
}

.label {
    font-weight: 600;
    font-size: 14px;
    margin-top: 1em;
}

.spinner {

}

.spinner :global(.spinner-border) {
    width: 22px;
    height: 22px;
}