.title {
    display: flex;
    align-items: baseline;
}

.title small {
    margin-left: 5px;
    font-size: 70%;
}

.promo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.promo img {
    display: block;
    width: 380px;
    height: auto;
}

.license {
    text-align: center;
    line-height: 1.4;
    margin-bottom: 30px;
}

.wizard {
    padding: 30px;
    box-shadow: 0 3px 13px rgba(0,0,0,.09);
    border: 1px solid #eee;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.statusConnected {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: #333;

    line-height: 1;
}
.statusConnectedIcon {
    color: green;
    font-size: 2em;
    margin-right: .2em;
}

.statusConnected strong {
    font-weight: 600;
    margin-right: .25em;
}


.loader {
    color: #999;
}

.help {
    margin-bottom: 1em;
}

.tutorial {

}