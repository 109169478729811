.card {
    position: relative;
}

@media (min-width: 992px) {
    .cardCta {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }
}

@media (max-width: 992px) {
    .cardCta {
        align-self: flex-start;
        margin: 20px;
    }
}