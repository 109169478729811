.iframe {
    display: block;
    width: 100%;
    height: 80vh;
    min-height: 1800px;
}

@media (max-width: 768px) {
    .iframe {
        min-height: 3200px;
    }
}