.logo {
    display: flex!important;
    align-items: center;
    flex: 1;
    height: 100%;
}

.logo span {
    display: flex;
    align-items: center;
}

.logo img {
    display: block;
    width: 24px;
    flex: 0;
    height: auto;
}

.logoText{
    margin-left: 12px;
}

body[data-sidebar=light] .logoText {
    color: #556ee6;
}

body[data-sidebar=dark] .logoText {
    color: white;
}