.widgetHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/** hide controls only on desktops **/
@media (min-width: 768px) {
    .widgetControl {
        opacity: 0;
        transition: opacity 199ms ease-out;
    }

    .widget:hover .widgetControl {
        opacity: 1;
    }
}

.widgetControlBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1;
    width: 20px;
    height: 20px;
}

.widgetControlBtn i {
    margin: 0!important;
}

.widgetBody {
    border: 1px solid rgba(0,0,0,.1);
    padding: 10px;
}

.widgetBody iframe {
    width: 100%;
    display: block;
    min-height: 200px;
}